<template>
    <div class="d-flex flex-column fill-height">
        <v-container class="tally-display rounded" :class="{'practice-mode': isPracticeMode}">
            <div class="d-flex justify-space-between">
                <p class="display-hint">Total:</p>
                <p class="display-total">{{total}}</p>
            </div>
            <div class="d-flex justify-space-between">
                <p class="display-hint">Current tally:</p>
                <p class="display-current">{{currentTally}}</p>
            </div>
            <v-progress-linear class="linear-countdown" :value="countdown / config.maxTime * 100" reverse/>
        </v-container>
        <v-container grow align-self-end>
            <v-row class="fill-height">
                <v-col v-for="(value, i) in config.values" :key="i" cols=6>
                    <TallyButton :value="value" v-on:add-tally="onAddTally" :disabled="isDisabled"/>
                </v-col>
                
            </v-row>
        </v-container>

        <v-container>
            <v-btn width="100%" @click="showMenu = true">
                <v-icon>mdi-dots-vertical</v-icon>
                Menu
            </v-btn>
        </v-container>

        <v-dialog v-model="isDisabled" max-width="500px" :retain-focus="false" persistent>
            <v-card>
                <v-card-title>
                    <span class="text-h4">Start a Tally Session</span>
                </v-card-title>

                <v-form v-model="valid">
                    <v-card-text>
                        <v-text-field 
                            v-model="guest.name" 
                            label="Your Name" 
                            outlined
                            :rules="[required('Name')]"/>
                        <v-text-field 
                            v-model="guest.email" 
                            label="Your Email" 
                            hint="A link to your tally report will be emailed to you." 
                            persistent-hint 
                            outlined
                            :rules="[required('Email'), emailFormat()]"/>
                    </v-card-text>

                    <v-card-text>
                        <v-text-field 
                            v-model="tallySession.name" 
                            label="Tally Session Name" 
                            hint="Provide a descriptive name for this tally session." 
                            outlined
                            persistent-hint />
                        <v-text-field 
                            v-model="tallySession.species" 
                            label="Species" 
                            hint="Describe the primary species of interest." 
                            outlined
                            persistent-hint />
                    </v-card-text>

                    <v-card-text>
                        <location-button v-on:location-update="updateLocation"/>
                    </v-card-text>

                    <v-card-text>
                        <v-checkbox :rules="[agreeRequired()]">
                            <template v-slot:label>
                                <div>
                                    I agree to the
                                    <router-link to="/terms" @click.stop>
                                        Terms and Conditions and Privacy Policy
                                    </router-link>
                                </div>
                            </template>
                        </v-checkbox>
                    </v-card-text>

                    <v-card-actions>
                        <v-tooltip bottom max-width="300px" close-delay="100ms">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn 
                                    color="error" 
                                    text
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="startPracticeMode">
                                    Practice Mode
                                </v-btn>
                            </template>
                            <span class="text-center">Test out the tally tool without storing your data or generating a report.</span>
                        </v-tooltip>
                        
                        <v-spacer></v-spacer>

                        <v-btn 
                            color="primary" 
                            @click="createTallySession"
                            :disabled="!valid">
                            Create
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
            
        </v-dialog>

        <v-dialog v-model="showMenu" max-width="300px">
            <v-card>
                <v-card-title>
                    <span class="text-h6">Edit Tally Session</span>
                    <v-spacer />
                    <close-button @close="showMenu = false" />
                </v-card-title>

                <v-card-actions>
                    <v-btn 
                        color="primary" 
                        @click="finalizeTallySession"
                        width="100%">
                        Finalize Tally Sesssion
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    
    </div>
</template>

<script>
import TallyButton from '@/components/TallyTool/TallyButton.vue';
import LocationButton from '@/components/TallyTool/LocationButton.vue';
import TallySession from '@/models/TallySession';
import PracticeTallySession from '@/models/PracticeTallySession';
import { mapFields } from 'vuex-map-fields';
import validations from "@/utils/validations";
import CloseButton from '@/components/CloseButton.vue';

export default {
    components: {
        TallyButton,
        LocationButton,
        CloseButton
    },
    data: () => ({
        config: {
            values: [10, 25, 50, 100, 250, 500],
            maxTime: 5
        },
        tallySession: null,
        currentTally: 0,
        countdown: 0,
        timer: null,
        valid: false,
        showMenu: false,
        ...validations
    }),
    methods: {
        onAddTally: function (value) {
            this.currentTally += value;
            
            if(!this.timer){
                this.countdown = this.config.maxTime;
                this.startTimer();
            }
        },
        startTimer: function () {
            this.timer = setInterval(() => {
                if (this.countdown > 0){
                    this.countdown -= .5;
                } else {
                    this.cancelTimer();
                    this.timerComplete();
                }
            }, 500);
        },
        cancelTimer: function () {
            clearInterval(this.timer)
            this.timer = null;
            this.countdown = 0;
        },
        timerComplete: function() {
            let tally = {
                count: this.currentTally,
                time: Date.now()
            };

            this.tallySession.addTally(tally);

            this.currentTally = 0;
        },
        updateLocation: function(location) {
            this.tallySession.location = JSON.parse(JSON.stringify(location));
        },
        startPracticeMode: function() {
            this.tallySession = new PracticeTallySession;
        },
        createTallySession: async function() {
            this.$store.dispatch('guest/save');
            await this.tallySession.put();
            this.$router.push({ name: 'tally-tool-existing', params: { id: this.tallySession._id } })
        },
        finalizeTallySession: async function() {
            await this.tallySession.finalize();
            if(!(this.tallySession instanceof PracticeTallySession)){
                this.$router.push({ name: 'tally-report', params: { id: this.tallySession._id } })
            }
        }
    },
    computed: {
        ...mapFields('guest', ['guest']),
        total: function() {
            return this.tallySession.getTotal();
        },
        isDisabled: function() {
            return !this.tallySession.isStarted();
        },
        isPracticeMode: function() {
            return this.tallySession instanceof PracticeTallySession;
        }
    },
    created() {
        this.tallySession = new TallySession;
        if(this.$route.params.id){
            this.tallySession.get(this.$route.params.id);
        }
    },
    beforeDestroy() {
        clearInterval(this.timer)
    }
}
</script>

<style lang="scss">
    .tally-display {
        background-color: #757575;
        &.practice-mode {
            background-color: #754545;
            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='18px' width='154px'><text x='0' y='15' font-family='Roboto, sans-serif' fill='%23ffffff20' font-size='20'>PRACTICE MODE</text></svg>");
            background-position: center;
            background-size: 60%;
        }
        p{
            text-align: right;
            line-height: 1;
        }
    }
    .display-hint{
        color: #ffffff80;
        font-size: 1.5rem;
    }
    .display-total{
        font-size: 5rem;
    }
    .display-current{
        font-size: 2.5rem;
    }
    .linear-countdown{
        transition: .5s linear;
    }
</style>