import Vue from 'vue'
import Vuex from 'vuex'
import countSession from './modules/countSession'
import countImages from './modules/countImages'
import users from './modules/users'
import guest from './modules/guest'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    countSession,
    countImages,
    users,
    guest
  }
})
