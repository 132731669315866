import Api from "@/services/api";

const state = {
  currentUser: {}
};

const getters = {
  isAdmin (state) {
    return (state.currentUser.roles && state.currentUser.roles.includes('ct-admin'))
  }
}

const mutations = {
  SET_CURRENT_USER (state, user) {
    state.currentUser = user
  }
};

const actions = {
  async login({ commit }, loginInfo) {
    try {
      let response = await Api().post('/user/login', loginInfo);
      let user = response.data;
      commit('SET_CURRENT_USER', user);
      return user
    } catch {
      return {error: "Email/password combination was incorrect.  Please try again."}
    }   
  },
  async logout({ commit }) {
    let response = await Api().get('/user/logout');
    console.log(response)
    if (response.data.status == "logged_out"){
       commit('SET_CURRENT_USER', {});
       return true;
    }
    return false;
  },
  async getUser({ commit }) {
    let response = await Api().get('/user/about');
    let user = response.data;
    commit('SET_CURRENT_USER', user);
  }
};

export default{
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};