import axios from 'axios';

export default () => {
  //let currentUserString = window.localStorage.currentUser;
  //let currentUser = currentUserString ? JSON.parse(currentUserString) : '';
  return axios.create({
    baseURL: process.env.VUE_APP_API.concat('/api'),
    withCredentials: true,
    headers: {
      //Accept: "application/json",
      //"Content-Type": "application/json",
      //Authorization: currentUser && currentUser.token
    }
  });
}