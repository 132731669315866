<template>
  <v-container>
    <h1>{{status}}</h1>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex';

  export default {
    components: {
      
    },
    data(){
      return {
        status: "Logging out..."
      }
    },
    methods: {
      ...mapActions('users', ['logout']),
    },
    async mounted() {
      const result = await this.logout();
      if(result){
        this.status = "Logged out!";
      } else {
        this.status = "Unable to log you out.";
      }
    }
  }
</script>
