<template>
  <v-app>
    
    <Navigation />
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import Navigation from '@/components/Navigation.vue';

export default {
  name: 'Crow.tools',
  components: {
    Navigation
  },
  data: () => ({
    //
  }),
  created(){
    this.$store.dispatch('guest/init');
  }
};
</script>

<style>
.v-application {
  font-family: 'Open Sans', sans-serif;
}
</style>
