<template>
    <div>
        <div class="row count-img-container mb-3">
            <img v-bind:src="currentImageUrl" />
        </div>
        <div class="row">
        </div>
        <v-row align="center">
            <v-col>
                <v-alert>{{resultText}}</v-alert>
            </v-col>
            <v-col class="col-sm">
                <v-text-field type="number" v-model.number="guess" :disabled="guessed" />
            </v-col>
            <v-col class="col-sm-auto">
                <v-btn v-on:click="checkAnswer" v-if="!guessed">Guess</v-btn>
                <v-btn v-on:click="$emit('next')" v-if="guessed">Next</v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import config from "@/utils/config";
export default {
    props: ["countImage"],
    setup() {
        
    },
    data() {
        return {
            guess: undefined,
            guessed: false,
            resultText: "Enter your guess:"
        }
    },
    computed: {
        rangeMax: function(){
            switch(this.countImage.size){
                case "small":
                    return 100;
                case "medium":
                    return 500;
                case "large":
                    return 1000;
                default:
                    return 250;
            }
        },
        score: function(){
            const maxScore = 10;
            const scorableRange = .5;
            const margin = this.rangeMax*scorableRange/2;
            const diff = Math.abs(this.countImage.count-this.guess);

            if (diff < margin){
                return Math.round((margin - diff)*maxScore/margin);
            } else {
                return 0;
            }
        },
        currentImageUrl() {
            return config.backendUrl.concat('/api/count-images/view/',this.countImage.fileId);
        }
    },
    methods: {
        ...mapActions('countSession', ['addResult']),
        checkAnswer: function(){
            this.guessed = true;
            this.resultText = `Correct answer: ${this.countImage.count}. Points awarded: ${this.score}`;
            this.addResult({result: {imageID: this.countImage._id, guess: this.guess}, score: this.score});
        }
    }
}
</script>

<style>
    .count-img-container {
        min-height: 0;
        //background: red;
    }
    .count-img-container img {
        height: 100%; 
        width: 100%; 
        object-fit: contain
    }
</style>
