<template>
  <v-container>
    <v-row justify=center>
      <v-col lg=4 md=6 sm=12>
        <v-row>
          <v-col
            v-for="(interactive, i) in interactives"
            :key="i"
            cols=12>
            <v-card :to="interactive.link">
              <v-card-title>{{ interactive.title }}</v-card-title>
              <v-card-subtitle>{{ interactive.description }}</v-card-subtitle>
              <v-card-actions v-if="interactive.available">
                <v-btn text :to="interactive.link">
                  <span class="mr-2">Launch</span>
                  <v-icon>mdi-open-in-new</v-icon>
                </v-btn>
              </v-card-actions>
              <v-card-actions v-if="!interactive.available">
                <v-btn text disabled>
                  Coming Soon
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Home',

    data: () => ({
      interactives: [
        {
          title: "Crow Counting Interactive",
          link: "/counting-interactive",
          description: "Test and practice your skills by counting large numbers of crows in a series of images.",
          available: true
        },
        {
          title: "Tally Tool",
          link: "/tally-tool",
          description: "Count hundreds and thousands of crows in the field, and keep track of your tally using this detailed logging and tallying tool.",
          available: true
        },
        {
          title: "Crow Density Interactive",
          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          available: false
        },
        {
          title: "Crow Audio Interactive",
          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
          available: false
        }
      ]
    }),
  }
</script>
