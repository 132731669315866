import api from "@/services/api";

const upload = function(file) {
  let formData = new FormData();

  formData.append("file", file);

  return api().post("/count-images/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

export default upload;