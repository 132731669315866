<template>
  <v-container>
    <h1>Admin page</h1>
    <v-data-table
      :headers="headers"
      :items="countImages"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar>
          <v-toolbar-title>Count Images</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialogNew" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                New
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Upload Image</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-file-input show-size truncate-length="30" accept="image/jpeg, image/jpg" label="Image" v-model="file"></v-file-input>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogNew = false">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="uploadImage">Next</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogEdit" max-width="600px">
            <v-card>
              <v-img
                :src="currentImageUrl"
              ></v-img>

              <v-card-title>
                <span class="text-h5">Edit Item</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field v-model="currentImage.fileId" label="File ID" disabled></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field v-model="currentImage.originalFileName" label="Original File Name" disabled></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field type="number" v-model.number="currentImage.count" label="Count"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select :items="countMethods" v-model="currentImage.countMethod" label="Count Method"></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete 
                        :items="tags" 
                        v-model="currentImage.tags" 
                        label="Tags" 
                        chips
                        deletable-chips
                        multiple></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogEdit = false">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="saveEdit">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete = false">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import upload from "@/services/fileUpload";
import config from "@/utils/config";

export default {
  data () {
    return {
      headers: [
        { text: 'ID', value: '_id' },
        { text: 'Original Name', value: 'originalFileName' },
        { text: 'Count', value: 'count' },
        { text: 'Method', value: 'countMethod' },
        { text: 'Tags', value: 'tags' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      countMethods: [
        "Manual",
        "Crow Counter v0.1",
        "Crow Counter v0.2"
      ],
      tags: [
        "narrow-towards-observer",
        "airborne",
        "roosted",
        "motion-blur",
        "rooftop",
        "close-distance",
        "medium-distance",
        "far-distance",
        "inconsistent-background"
      ],
      file: undefined,
      uploadedImage: undefined,
      dialogNew: false,
      dialogEdit: false,
      dialogDelete: false,
      currentImage: {
        count: undefined,
        countMethod: undefined,
        tags: [],
        fileId: undefined,
        originalFileName: undefined
      },
      defaultImage: {
        count: undefined,
        countMethod: undefined,
        tags: [],
        fileId: undefined,
        originalFileName: undefined
      }
    }
  },
  methods: {
    //...mapActions('countImages', ['loadCountImages', 'createImage', 'deleteImage']),
    deleteItem (image) {
      this.currentImage = Object.assign({}, image);
      this.dialogDelete = true
    },
    uploadImage() {
      if (!this.file) {
        alert("Please select a file!");
        return;
      }
      this.currentImage = Object.assign({}, this.defaultImage);
      upload(this.file)
        .then((response) => {
          this.currentImage.fileId = response.data.file.filename;
          this.currentImage.originalFileName = response.data.file.originalname;
          this.dialogNew = false;
          this.dialogEdit = true;
        })
        .catch(() => {
          this.message = "Could not upload the file!";
          this.file = undefined;
        });
    },
    async deleteItemConfirm () {
      await this.$store.dispatch('countImages/deleteImage', this.currentImage);
      this.currentImage = Object.assign({}, this.defaultImage);
      this.dialogDelete = false;
    },
    editItem(image) {
      this.currentImage = Object.assign({}, image)
      this.dialogEdit = true;
    },
    async saveEdit() {
      if (this.currentImage._id){
        await this.$store.dispatch('countImages/updateImage', this.currentImage);
      } else {
        await this.$store.dispatch('countImages/createImage', this.currentImage);
      }
      this.dialogEdit = false;
      this.currentImage = Object.assign({}, this.defaultImage);
    },
  },
  computed: {
    ...mapGetters('countImages', ['countImages']),
    currentImageUrl() {
      return config.backendUrl.concat('/api/count-images/view/',this.currentImage.fileId);
    }
  },
  created() {
    this.$store.dispatch('countImages/loadCountImages');
  },
}
</script>