import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import CountingInteractive from '@/views/CountingInteractive.vue'
import TallyToolTally from '@/views/TallyTool/Tally.vue'
import Admin from '@/views/Admin.vue'
import Login from '@/views/Login.vue'
import Logout from '@/views/Logout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/counting-interactive',
    name: 'counting-interactive',
    component: CountingInteractive
  },
  {
    path: '/tally-tool',
    name: 'tally-tool-new',
    component: TallyToolTally
  },
  {
    path: '/tally-tool/:id/tally',
    name: 'tally-tool-existing',
    component: TallyToolTally
  },
  {
    path: '/tally-tool/:id/report',
    name: 'tally-report',
    component: () => import('@/views/TallyTool/Report.vue')
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('@/views/Terms.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/Contact.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    beforeEnter(to, from, next) {
      if(store.getters['users/isAdmin']) {
        next();
      } else {
        next("/login");
      }
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
