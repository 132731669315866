<template>
    <div>
        <v-app-bar app color="primary" dark >
            <v-container class="pa-0 h-100 d-flex align-center">
                <img
                    alt="Crow Tools Logo"
                    class="h-100 navbar-brand"
                    
                    src="@/assets/crow-tools-lockup-primarybg.svg"
                />

                <v-spacer></v-spacer>

                <v-btn 
                    class="d-none d-md-inline-flex"
                    v-for="item in navItems.filter(i => i.bar)"
                    :key="item.link"
                    :to="item.link"
                    text>
                    {{ item.label }}
                </v-btn>

                <v-app-bar-nav-icon class="d-md-none" @click="drawer = true"></v-app-bar-nav-icon>
            </v-container>
        </v-app-bar>

        <v-navigation-drawer v-model="drawer" app temporary right>
            <v-list nav>

                <v-list-item
                    v-for="item in navItems.filter(i => i.drawer)"
                    :key="item.link"
                    :to="item.link">
                    <v-list-item-icon>
                        <v-icon>{{item.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{item.label}}</v-list-item-title>
                </v-list-item>

            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
export default {
    data: () => ({
        drawer: false,
        navItems: [
            {
                label: 'Home',
                link: '/',
                icon: 'mdi-home',
                bar: true,
                drawer: true,
            }, 
            {
                label: 'About',
                link: '/about',
                icon: 'mdi-information',
                bar: true,
                drawer: true,
            }, 
            {
                label: 'Contact Us',
                link: '/contact',
                icon: 'mdi-email-send-outline',
                bar: true,
                drawer: true,
            }
        ]
    }),
}
</script>

<style lang="scss">
.h-100{
    height: 100%;
}
.navbar-brand{
    display: inline-block;
}
</style>