<template>
    <v-btn :color="buttonColor" :disabled="!locationSupport" v-on:click="getLocation">
        <v-icon left>{{buttonIcon}}</v-icon>
        {{buttonText}}
    </v-btn>
</template>

<script>
export default {
    data(){
        return {
            locationSupport: null,
            point: null,
            options: {
                maximumAge: 0,
                enableHighAccuracy: true
            }
        }
    },
    created(){
        this.locationSupport = Boolean(navigator.geolocation);
    },
    computed: {
        buttonColor: function(){
            if (this.locationSupport){
                return (this.point ? "success" : "normal");
            } else {
                return "error";
            }
        },
        buttonIcon: function(){
            return (this.point ? "mdi-check" : "mdi-crosshairs-gps");
        },
        buttonText: function(){
            if (this.locationSupport){
                return (this.point ? "Location Acquired" : "Get Location");
            } else {
                return "Location Unavailable";
            }
        },
    },
    methods: {
        getLocation: function(){
            navigator.geolocation.getCurrentPosition(this.onSuccess, this.onError, this.options);
        },
        onSuccess: function(position){
            console.log(position);
            this.point = {
                type: "Point",
                coordinates: [position.coords.longitude, position.coords.latitude]
            }
            console.log(this.point.location);
            this.$emit("location-update", this.point);
        },
        onError: function(error){
            this.locationSupport = false;
            console.log(error);
        }
    }
}
</script>

<style>

</style>