import api from "@/services/api";

const state = {
  countImages: []
};

const getters = {
  countImages: (state) => state.countImages
  //countResults: (state) => state.results
};

const mutations = {
  LOAD_COUNT_IMAGES: (state, countImages) => (state.countImages = countImages),
  CREATE_IMAGE: (state, image) => (state.countImages.push(image)),
  //UPDATE_IMAGE: (state, image) => (state.countImages = state.videos.filter(v => v.id != videoId)),
  DELETE_IMAGE: (state, image) => (state.countImages = state.countImages.filter(i => i._id != image._id))
};

const actions = {
  async loadCountImages({ commit }) {
    const images = await api().get('/count-images');
    commit('LOAD_COUNT_IMAGES', images.data);
  },
  async createImage({ commit },  image) {
    const response = await api().post('/count-images', image);
    if (response.status == 201){
      commit('CREATE_IMAGE', response.data);
      return true;
    }
    return false;
  },
  async updateImage({ commit },  image) {
    const response = await api().post('/count-images/update', image);
    if (response.status == 200){
      commit('DELETE_IMAGE', image);
      commit('CREATE_IMAGE', image);
      return true;
    }
    return false;
  },
  async deleteImage({ commit },  image) {
    const response = await api().delete('/count-images', {data: {_id: image._id}});
    if (response.status == 200){
      commit('DELETE_IMAGE', image);
      return true;
    }
    return false;
  }
};

export default{
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};