import Api from "@/services/api";
import { getField, updateField } from 'vuex-map-fields';

const state = {
  guest: {
    key: null,
  }
};

const getters = {
  guest: (state) => state.guest,
  key: (state) => state.guest.key,
  getField
};

const mutations = {
  LOAD_STORAGE(state) {
    try{
      const store = localStorage.getItem('ct-guest');
      if(store) {
        state.guest = JSON.parse(store);
      }
    } catch {
      localStorage.removeItem('ct-guest')
    }
  },
  SET_GUEST(state, guest) {
    let key = state.guest.key;
    state.guest = guest;
    if(!guest.key){
      state.guest.key = key;
    }
    localStorage.setItem('ct-guest', JSON.stringify(state.guest));
  },
  updateField
};

const actions = {
  async init({ commit }) {
    commit('LOAD_STORAGE')
    if(!state.guest._id){
      let response = await Api().put('/guest');
      commit('SET_GUEST', response.data.guest);
    }
  },
  async save({ commit }) {
    let response = await Api().post('/guest', state.guest);
    if(response.data.guest){
      commit('SET_GUEST', response.data.guest)
    }
    return state.guest;
  }
};

export default{
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};