let required = (propertyType) => { 
  return v => v && v.length > 0 || `${propertyType} is required`
}
let agreeRequired = () => { 
  return v => v || "You must agree to continue"
}
let minLength = (propertyType, minLength) => {
  return v => v && v.length >= minLength || `${propertyType} must be at least ${minLength} characters`
}
let maxLength = (propertyType, maxLength) => {
  return v => v && v.length <= maxLength || `${propertyType} must be less than ${maxLength} characters`
}

let emailFormat = () => {
  let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,24})+$/
  return v => v && regex.test(v) || "Must be a valid email"
}

export default {
  required,
  agreeRequired,
  minLength,
  maxLength,
  emailFormat
}