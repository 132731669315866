<template>
    <v-container>
        <div class="row mb-3 justify-content-center">
            <div class="col col-auto">
                <h3 class="h3 mb-0">Score: {{this.score}}</h3>
            </div>
        </div>
        <CountImage v-if="(typeof currentCountImage !== 'undefined' && this.showCount)" :countImage="currentCountImage" v-on:next="nextImage"/>
    </v-container>
</template>

<script>
import Vue from 'vue';
import CountImage from '../components/CountImage.vue'
import { mapGetters, mapActions } from 'vuex';
export default {
    components: {
        CountImage
    },
    data() {
        return {
            imageIndex: 0,
            showCount: true
        }
    },
    setup() {
        
    },
    methods: {
        ...mapActions('countSession', ['initSession']),
        nextImage: function(){
            this.showCount = false;
            this.imageIndex += 1;
            Vue.nextTick(() => {
				this.showCount = true;
			});
        }
    },
    computed: {
        ...mapGetters('countSession', ['countImages', 'score']),
        currentCountImage: function(){
            return this.countImages[this.imageIndex];
        }
    },
    created() {
        //this.fetchCountImages();
        this.initSession();
    }
}
</script>