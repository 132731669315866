import Api from "@/services/api";
import store from "@/store"

export default class TallySession {
    tallies = null;
    _id = null;
    owner = { name: null };
    location = {
        coordinates: null
    }

    put(){
        return Api().put('/tally-session', {
            guest: store.getters['guest/guest'],
            tallySession: this
        }).then((response) => {
            Object.assign(this, response.data);
            this.tallies.forEach(tally => {
                tally.time = new Date(tally.time);
            });
        })
    }

    isStarted(){
        return Boolean(this._id);
    }

    get(_id){
        return Api().get('/tally-session/'+_id, { 
            params: { key: store.getters['guest/key'] } 
        }).then((response) => {
            Object.assign(this, response.data.tallySession);
            this.tallies.forEach(tally => {
                tally.time = new Date(tally.time);
            });
            this.owner = response.data.owner;
        });
    }

    addTally(tally){
        return Api().post('/tally-session/'+this._id+'/add-tally', tally, { 
            params: { key: store.getters['guest/key'] } 
        }).then((response) => {
            this.tallies.push(response.data.tally);
        });
    }

    getTotal() {
        var totalCounter = 0;
 
        if(this.tallies){
            this.tallies.forEach(tally => {
                totalCounter += tally.count;
            });
        }
        
        return totalCounter;
    }

    getVelocity() {
        // set interval to 1 minute
        let interval = 1000 * 60 * 1;

        var velocity = 0;
        if (this.tallies){
            var total = this.getTotal();
            var duration = (this.tallies[this.tallies.length - 1].time.getTime() - this.tallies[0].time.getTime()) / interval;
            if (duration == 0){
                velocity = total;
            } else {
                velocity = total/duration;
            }
        }
        return velocity;
    }

    getBinnedStats() {
        let interval = 1000 * 60 * 1;

        var bins = [];

        if(this.tallies){
            this.tallies.sort((a, b) => a.time - b.time);

            var currentBin = {
                start: new Date(Math.floor(this.tallies[0].time.getTime() / interval) * interval),
                count: 0
            }
            currentBin.end = new Date(currentBin.start.getTime() + interval);

            this.tallies.forEach(tally => {
                if (currentBin && tally.time < currentBin.end){
                    currentBin.count += tally.count;
                } else {
                    while (tally.time > currentBin.end) {
                        bins.push(currentBin);
                        currentBin = {
                            start: new Date(currentBin.start.getTime() + interval),
                            count: 0,
                            end: new Date(currentBin.end.getTime() + interval)
                        }
                    }
                    currentBin.count += tally.count;
                }
            });
            bins.push(currentBin);
        }

        return bins;
    }

    finalize(){
        return Api().post('/tally-session/'+this._id+'/finalize', null, { 
            params: { key: store.getters['guest/key'] } 
        });
    }
}