export default class PracticeTallySession {
    tallies = [];
    _id = null;
    owner = { name: null };
    location = {
        coordinates: null
    }

    put(){
        return;
    }

    isStarted(){
        return true;
    }

    addTally(tally){
        this.tallies.push(tally);
    }

    getTotal() {
        var totalCounter = 0;
 
        if(this.tallies){
            this.tallies.forEach(tally => {
                totalCounter += tally.count;
            });
        }
        
        return totalCounter;
    }

    getVelocity() {
        // set interval to 1 minute
        let interval = 1000 * 60 * 1;

        var velocity = 0;
        if (this.tallies){
            var total = this.getTotal();
            var duration = (this.tallies[this.tallies.length - 1].time.getTime() - this.tallies[0].time.getTime()) / interval;
            if (duration == 0){
                velocity = total;
            } else {
                velocity = total/duration;
            }
        }
        return velocity;
    }

    getBinnedStats() {
        let interval = 1000 * 60 * 1;

        var bins = [];

        if(this.tallies){
            this.tallies.sort((a, b) => a.time - b.time);

            var currentBin = {
                start: new Date(Math.floor(this.tallies[0].time.getTime() / interval) * interval),
                count: 0
            }
            currentBin.end = new Date(currentBin.start.getTime() + interval);

            this.tallies.forEach(tally => {
                if (currentBin && tally.time < currentBin.end){
                    currentBin.count += tally.count;
                } else {
                    while (tally.time > currentBin.end) {
                        bins.push(currentBin);
                        currentBin = {
                            start: new Date(currentBin.start.getTime() + interval),
                            count: 0,
                            end: new Date(currentBin.end.getTime() + interval)
                        }
                    }
                    currentBin.count += tally.count;
                }
            });
            bins.push(currentBin);
        }

        return bins;
    }

    finalize(){
        return;
    }
}