<template>
  <v-btn class="tally-button" v-on:click="$emit('add-tally', value)" :disabled="disabled">{{value}}</v-btn>
</template>

<script>
export default {
    props: ["value", "disabled"],
}
</script>

<style>
    .tally-button {
        width: 100%;
        height: 100% !important;
        font-size: 3.5rem !important;
    }
</style>