<template>
  <v-container>
    <h1>Login</h1>
    <v-form v-model="valid">
      <v-text-field v-model="userInfo.username" 
        label="Username" 
        :rules="[required('Username')]"/>

      <v-text-field v-model="userInfo.password"
        label="Password"
        type="password"
        :rules="[required('Password'), minLength('Password', 8)]"
        />

      <v-btn @click="loginUser()" :disabled="!valid">Login</v-btn>
    </v-form>
  </v-container>
</template>

<script>
  import validations from "@/utils/validations";
  import { mapActions } from 'vuex';

  export default {
    components: {
      
    },
    data(){
      return {
        valid: false,
        userInfo: {
          username: '',
          password: ''
        },
        ...validations
      }
    },
    methods: {
      ...mapActions('users', ['login']),
      async loginUser() {
        let user = await this.login(this.userInfo);
        if(user.error){
          console.log("user sign in error")
          //this.$store.dispatch('snackbar/setSnackbar', {color: 'error', text: user.error});
        } else {
          console.log("user signed in")
          //this.$store.dispatch('snackbar/setSnackbar', {text: 'Thank you for signing in, ' + user.name});
          this.$router.push('/admin');
        }
      }
    },
  }
</script>
