import api from "@/services/api";

const state = {
  _id: undefined,
  key: undefined,
  score: undefined,
  name: undefined,
  email: undefined,
  results: [],
  countImages: []
};

const getters = {
  score: (state) => state.score,
  countImages: (state) => state.countImages
  //countResults: (state) => state.results
};

const mutations = {
  updateCountSession: (state, result) => (
    state._id = result._id,
    state.key = result.key,
    state.score = result.score,
    state.name = result.name,
    state.email = result.email,
    state.results = result.results
  ),
  updateSessionImages: (state, countImages) => (state.countImages = countImages),
  addScore: (state, score) => (
    state.score += score
  )
};

const actions = {
  async initSession({ commit }) {
    const session = await api().post('/count-session/new');
    commit('updateCountSession', session.data);
    const images = await api().get('/count-images/session')
    commit('updateSessionImages', images.data)
  },
  async addResult({ commit, state }, {result, score}) {
    commit('addScore', score);
    const response = await api().post('/count-session/add-result', {_id: state._id, key: state.key, score: state.score, result})
    commit('updateCountSession', response.data)
  }
};



export default{
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};